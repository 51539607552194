:root {
    --spacing-8: 8px;
    --spacing-16: 16px;
    --spacing-32: 32px;
    --spacing-64: 64px;
    --spacing-128: 128px;
}
.mt-8 {
    margin-top: var(--spacing-8);
}
.mt-16 {
    margin-top: var(--spacing-16);
}
.mt-32 {
    margin-top: var(--spacing-32);
}
.mt-64 {
    margin-top: var(--spacing-64);
}
.mt-128 {
    margin-top: var(--spacing-128);
}
.ml-8 {
    margin-left: var(--spacing-8);
}
.ml-16 {
    margin-left: var(--spacing-16);
}
.ml-32 {
    margin-left: var(--spacing-32);
}
.ml-64 {
    margin-left: var(--spacing-64);
}
.ml-128 {
    margin-left: var(--spacing-128);
}
.mr-8 {
    margin-right: var(--spacing-8);
}
.mr-16 {
    margin-right: var(--spacing-16);
}
.mr-32 {
    margin-right: var(--spacing-32);
}
.mr-64 {
    margin-right: var(--spacing-64);
}
.mr-128 {
    margin-right: var(--spacing-128);
}
.mb-8 {
    margin-bottom: var(--spacing-8);
}
.mb-16 {
    margin-bottom: var(--spacing-16);
}
.mb-32 {
    margin-bottom: var(--spacing-32);
}
.mb-64 {
    margin-bottom: var(--spacing-64);
}
.mb-128 {
    margin-bottom: var(--spacing-128);
}
.m-8 {
    margin: var(--spacing-8) var(--spacing-8) var(--spacing-8) var(--spacing-8)
}
.m-16 {
    margin: var(--spacing-16) var(--spacing-16) var(--spacing-16) var(--spacing-16)
}
.m-32 {
    margin: var(--spacing-32) var(--spacing-32) var(--spacing-32) var(--spacing-32)
}
.m-64 {
    margin: var(--spacing-64) var(--spacing-64) var(--spacing-64) var(--spacing-64)
}
.m-128 {
    margin: var(--spacing-128) var(--spacing-128) var(--spacing-128) var(--spacing-128)
}
.pt-8 {
    padding-top: var(--spacing-8);
}
.pt-16 {
    padding-top: var(--spacing-16);
}
.pt-32 {
    padding-top: var(--spacing-32);
}
.pt-64 {
    padding-top: var(--spacing-64);
}
.pt-128 {
    padding-top: var(--spacing-128);
}
.pl-8 {
    padding-left: var(--spacing-8);
}
.pl-16 {
    padding-left: var(--spacing-16);
}
.pl-32 {
    padding-left: var(--spacing-32);
}
.pl-64 {
    padding-left: var(--spacing-64);
}
.pl-128 {
    padding-left: var(--spacing-128);
}
.pr-8 {
    padding-right: var(--spacing-8);
}
.pr-16 {
    padding-right: var(--spacing-16);
}
.pr-32 {
    padding-right: var(--spacing-32);
}
.pr-64 {
    padding-right: var(--spacing-64);
}
.pr-128 {
    padding-right: var(--spacing-128);
}
.pb-8 {
    padding-bottom: var(--spacing-8);
}
.pb-16 {
    padding-bottom: var(--spacing-16);
}
.pb-32 {
    padding-bottom: var(--spacing-32);
}
.pb-64 {
    padding-bottom: var(--spacing-64);
}
.pb-128 {
    padding-bottom: var(--spacing-128);
}
.p-8 {
    padding: var(--spacing-8) var(--spacing-8) var(--spacing-8) var(--spacing-8)
}
.p-16 {
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-16) var(--spacing-16)
}
.p-32 {
    padding: var(--spacing-32) var(--spacing-32) var(--spacing-32) var(--spacing-32)
}
.p-64 {
    padding: var(--spacing-64) var(--spacing-64) var(--spacing-64) var(--spacing-64)
}
.p-128 {
    padding: var(--spacing-128) var(--spacing-128) var(--spacing-128) var(--spacing-128)
}
.block {
    display: block;
}