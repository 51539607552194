.jobjump-button {
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    border-width: 0px!important;
    font: var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-18)/var(--unnamed-line-spacing-31) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0-36);
    color: var(--jobjump-white);
    text-align: center;
    font: normal normal medium 18px/31px Poppins;
    letter-spacing: 0.36px;
    padding-top:var(--spacing-8);
    padding-bottom: var(--spacing-8);
}

.jobjump-button > h4 {
    color: var(--jobjump-white);
}

.jobjump-button-full-width { 
    width: 100%;
}

.jobjump-button:hover {
    opacity: 0.5 !important;
}

.jobjump-button-active {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
}

.jobjump-button-active.jobjump-button-glowing,
.jobjump-button-active:hover {
    box-shadow: 0px 10px 30px var(--primary-color-50);
}

.jobjump-button-neutral {
    background: var(--jobjump-neutral) 0% 0% no-repeat padding-box;
}

.jobjump-button-neutral.jobjump-button-glowing,
.jobjump-button-neutral:hover {
    box-shadow: 0px 10px 30px var(--jobjump-neutral-50);
}

.jobjump-button-negative {
    background: var(--jobjump-negative) 0% 0% no-repeat padding-box;
}

.jobjump-button-negative.jobjump-button-glowing,
.jobjump-button-negative:hover {
    box-shadow: 0px 10px 30px var(--jobjump-negative-50);
}

.jobjump-button-disabled {
    background: var(--jobjump-grey) 0% 0% no-repeat padding-box;
}

.jobjump-button-text {
    background: transparent;
    padding: 0;
}

.jobjump-button-text.jobjump-button-active {
    color: var(--primary-color);
}

.jobjump-button-text.jobjump-button-neutral {
    color: var(--jobjump-neutral)
}

.jobjump-button-text.jobjump-button-negative {
    color: var(--jobjump-negative)
}

.jobjump-button-text.jobjump-button-disabled {
    color: var(--jobjump-light-grey)
}

.jobjump-button-text:hover {
    box-shadow: unset;
}