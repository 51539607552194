.jobjump-secondary-menu {
    background: var(--jobjump-white) 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 60px #8F8F8F1A;
    border: 6px solid #F2F2F2;
    border-radius: 10px 10px 0px 0px;
    max-width: 1264px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: var(--spacing-128);
}

.jobjump-secondary-menu-title-row {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
    display: flex;
}

.jobjump-secondary-menu-title {
    flex: 2;
}

.jobjump-secondary-menu-secondary {
    margin-left:auto;
    margin-right: var(--spacing-32);
    order: 2;
}

.jobjump-secondary-menu-item-row {
    display:flex
}

.jobjump-secondary-menu-item-row .jobjump-secondary-menu-item:not(:first-child) {
    border-left: solid 2px #F2F2F2;
}

.jobjump-secondary-menu-item {
    flex-grow: 1;
    max-width: 158px;
}
.jobjump-secondary-menu-button {
    cursor: pointer;
    text-align: center;
    border-top: solid 2px #F2F2F2;
    border-bottom: none;
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
    height: 100%;
}

.jobjump-secondary-menu-button:hover {
    transform: scale(1.1);
    background: #fff;
    box-shadow: 0px 20px 50px 0px rgb(26 26 26 / 9%);
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
}
.jobjump-secondary-menu-item:hover > .jobjump-secondary-dropdown {
    transform: scale(1);
}

.jobjump-secondary-menu-dull > .jobjump-secondary-menu-icon > svg * path,
.jobjump-secondary-menu-dull > .jobjump-secondary-menu-icon > svg * rect {
    fill: var(--jobjump-grey);
}

.jobjump-secondary-menu-highlight > .jobjump-secondary-menu-icon > svg * path,
.jobjump-secondary-menu-highlight > .jobjump-secondary-menu-icon > svg * rect {
    color: var(--primary-color);
}

.jobjump-secondary-menu-icon {
    padding-bottom: var(--spacing-16);
}

.jobjump-secondary-menu-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: center;
    font: normal normal normal 12px/14px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}

.jobjump-secondary-dropdown-show {
    display:block;
}

.jobjump-secondary-dropdown-hide {
    display:none;
}

.jobjump-secondary-dropdown {
    position: absolute;
    margin-top: 103px;
    z-index: 10;
    text-align: left;
}

.jobjump-secondary-menu-item.active {
    border-bottom: solid 5px var(--primary-color);
}