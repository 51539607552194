.progressbar {
    counter-reset: step;
    display:flex;
    justify-content: center;
}
.progressbar li {
    list-style-type: none;
    position: relative;
    text-align: center;
    color: var(--jobjump-grey);
    margin-left:var(--spacing-32);
    margin-right:var(--spacing-32);
}
.progressbar li:first-child {
    margin-left:0px;
}
.progressbar li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 20px;
    border: 2xp solid var(--jobjump-grey);
    display: block;
    text-align: center;
    border-radius: 50%;
    margin: 0 auto 0px auto;
    background-color: white;
}
.progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: var(--jobjump-grey);
    top: 15px;
    left: -100%;
    z-index: -1;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: var(--primary-color);
}
.progressbar li.active:before {
    border-color: var(--primary-color-50);
}
.progressbar li.active + li:after {
    background-color: var(--primary-color-50);
}