.jobjump-card-image {
    box-shadow: 20px 20px 60px #0000001A;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    width: 100%;
    height: 300px;
}

.jobjump-image-card-disabled {
    opacity: 0.5;
}

.jobjump-full-image-card img {
    height: 100% !important;
}

.jobjump-card-image.cover {
    object-fit: cover;
}

.jobjump-card-image.contain {
    object-fit: contain;
}

.jobjump-image-card:hover {
    transform: scale(1.01);
    z-index: 50;
    box-shadow: 20px 20px 60px #00000036;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
}

.jobjump-image-card {
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
}

.image-card-selected {
    background-color: var(--primary-color);
}

.image-card-selected-glow,
.image-card-selected-glow:hover {
    box-shadow: 0px 0px 30px 10px var(--primary-color);
}

.image-card-selected h4 {
    color:white;
}

.image-card-no-padding {
    padding: 0px 0px 0px 0px !important;
}