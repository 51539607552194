.jobjump-row-card.jobjump-row-card-selectable:hover {
    cursor: pointer;
    background-color: var(--jobjump-lighter-grey);
}

.jobjump-row-card.jobjump-row-card-selected {
    background-color: var(--jobjump-lighter-grey);
    border-left: solid 11px var(--primary-color);
}

.jobjump-row-card > .jj-row {
    width: 100% !important;
}