.filter-grid-select-selected,
.filter-grid-select-unselected {
    height: 240px;
}

.filter-grid-select-selected .jobjump-card-image,
.filter-grid-select-unselected  .jobjump-card-image {
    height: 140px;
}

.filter-grid-select-selected {
    background-color: var(--primary-color);
}
.filter-grid-select-selected h4 {
    color:white;
}
