.jobjump-breadcrumb > a {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: center;
    font: normal normal normal 12px/14px Poppins;
    letter-spacing: 0px;
}

.page-hero-wrapper {
    max-width: 600px;
    margin: auto;
}