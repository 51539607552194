:root {
  --row-width: 1568px;
  --md-min-width: 640px;
  --lg-min-width: 1024px;
}

.jj-row {
    display: flex;
    flex-flow: row wrap;
    max-width: var(--row-width);
    margin-right: auto;
    margin-left: auto;
  }
  
  .jj-row.expanded {
    max-width: none;
  }

  .jj-row.small {
    max-width: 600px;
    margin: auto;
  }

  .jj-row.very-small {
    max-width: 450px;
    margin: auto;
  }
   
  .flex-start {
    justify-content: flex-start;
  }
  
  .center {
    justify-content: center;
  }
  
  .flex-end {
    justify-content: flex-end;
  }
  
  .space-between {
    justify-content: space-between;
  }
  
  .space-around {
    justify-content: space-around;
  }
  
  .space-evenly {
    justify-content: space-evenly;
  }
  
  .align-center {
    align-items: center;
  }
  
  .jj-column {
    flex: 1 1 0px;
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
    box-sizing: border-box;
  }

  .jj-column-no-padding {
    padding-left: 0;
    padding-right: 0;
  }
  
/*  .jj-column > .jj-row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }*/
  
  .jj-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  
  .jj-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  
  .jj-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .jj-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  
  .jj-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  
  .jj-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .jj-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  
  .jj-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  
  .jj-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .jj-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  
  .jj-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  
  .jj-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  @media screen and (min-width: 640px) {
    .jj-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
  
    .jj-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
  
    .jj-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  
    .jj-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  
    .jj-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
  
    .jj-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .jj-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
  
    .jj-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
  
    .jj-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
  
    .jj-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
  
    .jj-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
  
    .jj-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .jj-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
  
    .jj-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
  
    .jj-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  
    .jj-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  
    .jj-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
  
    .jj-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .jj-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
  
    .jj-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
  
    .jj-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
  
    .jj-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
  
    .jj-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
  
    .jj-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }