/*Control which menu is visible*/

@media screen and (min-width: 1220px) {
    :root {
        --menu-height: 120px;
        --menu-margin: 16px;
        --menu-line-height: 25px;
    }
    .jobjump-full-nav {
        display: block;
    }
    .jobjump-mobile-nav {
        display: none;
    }
    .jobjump-logo {
        max-height: calc(var(--menu-height) - var(--menu-margin));
    }
    .jj-menu-item {
        text-align: center;
        height:var(--menu-height);
        margin-top:var(--menu-margin);
        margin-bottom:var(--menu-margin);
        line-height: var(--menu-line-height);
        position: relative;
        padding-left:var(--spacing-32);
        padding-right:var(--spacing-32);
    }

    .jobjump-menu-lhs {
        float:left;
        display: flex; 
    }

    .jobjump-menu-rhs { 
        display: flex; 
    }

    .jobjump-menu-rhs > .jj-menu-item {
        margin-left:auto;
    }

    /* Bit of a hack (https://stackoverflow.com/questions/2717480/css-selector-for-first-element-with-class) */
    .jobjump-menu-rhs > .jj-menu-item ~  .jj-menu-item {
        margin-left: inherit;
    }

    .jj-menu-item > .menu-text {
        display:block;
        margin-top: calc((var(--menu-height)/2) - var(--menu-line-height));
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-31) var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0-36);
        color: var(--jobjump-black);
        font: normal normal normal 18px/31px Poppins;
        letter-spacing: 0.36px;
        cursor: pointer;
    }

    .jj-menu-item > .menu-text:hover {
        color: var(--jobjump-black);
        text-decoration: underline;
    }


    .jj-menu-item > img {
        height: calc(var(--menu-height) - (2*var(--menu-margin)));
    }

    .jobjump-menu-subitem {
        display:block;
    }

    .jobjump-menu-subitem > p {
        line-height: 10px;
    }

    .jobjump-menu-subitem > p > a {
        font-size: 14px;
        color: var(--primary-color);
        cursor: pointer;
    }

    .jobjump-menu-subitem > p > a:hover { 
        color: var(--primary-color);
        text-decoration: underline;
    }

    .hide-dropdown {
        display: none !important;
    }

    .jobjump-menu-popout {
        display: block;
        position: absolute;
        z-index: 100;
        width:200px;
    }

    .jobjump-menu-popout.jobjump-menu-popout-lg {
        width: 350px;
    }

    .jobjump-menu-rhs * .jobjump-menu-popout {
        right: 0;
    }

    .jobjump-submenu {
        padding-top: var(--spacing-32) !important;
    }
}

@media not screen and (min-width: 1220px) {
    :root {
        --menu-height: 96px;
        --menu-margin: 8px;
        --menu-line-height: 25px;
        --logo-height: 80px;
    }

    .jobjump-full-nav {
        display: none;
    }
    .jobjump-mobile-nav {
        display: block;
    }
    .jobjump-logo {
        height: var(--logo-height);
        margin-top: var(--menu-margin);
        margin-bottom: var(--menu-margin);
    }
    .jj-menu-item {
        height:var(--menu-height);
        margin-top: calc(calc(var(--menu-margin) * 2 + var(--logo-height) - var(--menu-line-height)) / 2);
        line-height: var(--menu-line-height);
        padding-left:var(--spacing-16);
        padding-right:var(--spacing-16);
        position: relative;
    }

}

.nav-row {
    display:flex;
}

.jobjump-menu-lhs {
    flex: 2 2;
}

.jobjump-menu-rhs {
    flex: 2 2;
}

.jj-menu-item > .menu-text:hover {
    cursor: pointer;
    color: var(--jobjump-black);
    text-decoration: underline;
}

.jobjump-full-nav-dropdown {
    padding-top:var(--spacing-32) !important;
    margin-left: var(--spacing-16);
    margin-right: var(--spacing-16);
    left: 0;
    right: 0;
    position: absolute;
    z-index: 100;
}

