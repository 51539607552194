.jobjump-badge {
    display: inline;
    border: none;
    cursor: default;
    height: 36px;
    outline: none;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
    font-size: 14px;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
}

.jobjump-badge-active {
    background: var(--primary-color);
    color: var(--jobjump-white);
}

.jobjump-badge-negative {
    background: var(--jobjump-negative);
    color: var(--jobjump-white);
}