@import url('https://fonts.googleapis.com/css2?family=Bitter&family=Indie+Flower&family=Inter&family=Lato&family=Poppins&family=Roboto&family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Architects+Daughter&family=Dancing+Script&family=Dosis&family=Inconsolata&family=Italianno&family=Open+Sans+Condensed:wght@300&family=Pacifico&family=Playfair+Display&family=Satisfy&family=Teko&display=swap');

.resume-template-card {
    padding: 8px 8px 8px 8px !important;
    height: 200px;
}

.bitter {
    font-family: 'Bitter', serif;
}

.indieFlower {
    font-family: 'Indie Flower', cursive;
}

.inter {
    font-family: 'Inter', sans-serif;
}

.lato {
    font-family: 'Lato', sans-serif;
}

.poppins {
    font-family: 'Poppins', sans-serif;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.shadowsIntoLight {
    font-family: 'Shadows Into Light', cursive;
}

.abril {
font-family: 'Abril Fatface', cursive;
}

.architect {
font-family: 'Architects Daughter', cursive;
}
.dancing {
font-family: 'Dancing Script', cursive;
     
}
.dosis {
font-family: 'Dosis', sans-serif;

}

.inconsolata {
font-family: 'Inconsolata', monospace;

}

.italianno {
font-family: 'Italianno', cursive;
}
.open-sans {
font-family: 'Open Sans Condensed', sans-serif;

}
.pacifico {
font-family: 'Pacifico', cursive;

}
.playfair {
font-family: 'Playfair Display', serif;

}
.satisfy {
font-family: 'Satisfy', cursive;

}
.teko {
font-family: 'Teko', sans-serif;
}