.det-ack {
    background: var(--jobjump-darker-grey) 0% 0% no-repeat padding-box;
    opacity: 1; 
    color: var(--jobjump-white);
}

.copyright {
    color: white;
    padding: 15px 0 5px;
    background-color: #1f1f1f!important;
    background-color: rgba(0,0,0,.32);
}

.copyright a {
    color: white;
}