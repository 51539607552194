.school-homepage-video {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 65vh;
    background: #000;
    overflow: hidden;
    background:black;
}

.school-video-container {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}

.school-video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.video-float {
    position: absolute;
    top: calc(50% - 5vh);
    width: 100%;
    z-index: 10;
}

.school-news-card {
    background: unset !important;
    border-radius: 0px !important;
    margin-left: 7px;
    margin-right: 7px;
    height: 20vh;
}

.school-news-card .card-header {
    font-size: 18px;
    font-weight: 700!important;
    text-transform: uppercase!important;
    text-align: center;
    background-color: #222222;
    color: white;
}

.school-news-card .card-body {
    background-color: white;
    overflow-y:auto;
}

.slick-track {
    display: flex !important;
}
  
.slick-slide {
    height: auto;
}

.careers-noticeboard-section {
    width: 65vw;
}

@media screen and (max-width:760px) {
    .careers-noticeboard-section {
        width: 100vw;
    }
}