@import url('https://fonts.googleapis.com/css?family=Parisienne&display=swap');

.modal-90w {
    min-width: 60% !important;
}

.dtborder {
   position:relative; /*important! */
   border:2px solid #000;
   background: #f9f9f9;
   padding:30px;
   border-radius: 0px;
}
.dtborder:before {
  content:"";
  position: absolute;
  /*set margins in other words its positioning our added pseudo content*/
  top:5px;
  bottom:5px;
  left:5px;
  right:5px;
  border:2px solid #999;
  border-radius: 0px;
}

.print-cert {
    height: 100%;
}

.print-cert h2 {
    font-size: 4rem;
}

.print-cert h3 {
    font-size: 3rem;
}

.print-cert p {
    font-size: 1.7rem;
}

.cursive-font {
    font-family: 'Parisienne', cursive;
    font-weight: bold;
}