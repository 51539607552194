.jj-rowcard-cell {
    flex: 1 1 0px;
    padding-left: var(--spacing-8);
    padding-right: var(--spacing-8);
    box-sizing: border-box;
}

.jj-cell-2 {
    flex: 0 0  4.166666%;
    max-width: 4.166666%;
}

.jj-cell-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.jj-cell-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}

.jj-cell-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.jj-cell-5 {
    flex: 0 0  20.83333%;
    max-width: 20.83333%;
}

.jj-cell-6 {
    flex: 0 0 25%;
    max-width: 25%;
}

.jj-cell-7 {
    flex: 0 0  29.16666%;
    max-width: 29.16666%;
}

.jj-cell-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.jj-cell-8 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}

.jj-cell-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.jj-cell-11 {
    flex: 0 0  45.83333%;
    max-width: 45.83333%;
}

.jj-cell-12 {
    flex: 0 0 50%;
    max-width: 50%;
}

.jj-cell-13 {
    flex: 0 0  54.16666%;
    max-width: 54.16666%;
}

.jj-cell-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.jj-cell-15 {
    flex: 0 0  62.5%;
    max-width: 62.5%;
}

.jj-cell-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.jj-cell-17 {
    flex: 0 0  70.83333%;
    max-width: 70.83333%;
}

.jj-cell-18 {
    flex: 0 0 75%;
    max-width: 75%;
}

.jj-cell-19 {
    flex: 0 0  79.16666%;
    max-width: 79.16666%;
}

.jj-cell-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.jj-cell-21 {
    flex: 0 0  87.5%;
    max-width: 87.5%;
}

.jj-cell-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.jj-cell-23 {
    flex: 0 0  95.83333%;
    max-width: 95.83333%;
}

.jj-cell-24 {
    flex: 0 0 100%;
    max-width: 100%;
}
