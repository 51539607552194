@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bitter&family=Indie+Flower&family=Inter&family=Lato&family=Poppins&family=Roboto&family=Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Architects+Daughter&family=Dancing+Script&family=Dosis&family=Inconsolata&family=Italianno&family=Open+Sans+Condensed:wght@300&family=Pacifico&family=Playfair+Display&family=Satisfy&family=Teko&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Control which menu is visible*/

@media screen and (min-width: 1220px) {
    :root {
        --menu-height: 120px;
        --menu-margin: 16px;
        --menu-line-height: 25px;
    }
    .jobjump-full-nav {
        display: block;
    }
    .jobjump-mobile-nav {
        display: none;
    }
    .jobjump-logo {
        max-height: calc(var(--menu-height) - var(--menu-margin));
    }
    .jj-menu-item {
        text-align: center;
        height:var(--menu-height);
        margin-top:var(--menu-margin);
        margin-bottom:var(--menu-margin);
        line-height: var(--menu-line-height);
        position: relative;
        padding-left:var(--spacing-32);
        padding-right:var(--spacing-32);
    }

    .jobjump-menu-lhs {
        float:left;
        display: flex; 
    }

    .jobjump-menu-rhs { 
        display: flex; 
    }

    .jobjump-menu-rhs > .jj-menu-item {
        margin-left:auto;
    }

    /* Bit of a hack (https://stackoverflow.com/questions/2717480/css-selector-for-first-element-with-class) */
    .jobjump-menu-rhs > .jj-menu-item ~  .jj-menu-item {
        margin-left: inherit;
    }

    .jj-menu-item > .menu-text {
        display:block;
        margin-top: calc((var(--menu-height)/2) - var(--menu-line-height));
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-31) var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0-36);
        color: var(--jobjump-black);
        font: normal normal normal 18px/31px Poppins;
        letter-spacing: 0.36px;
        cursor: pointer;
    }

    .jj-menu-item > .menu-text:hover {
        color: var(--jobjump-black);
        text-decoration: underline;
    }


    .jj-menu-item > img {
        height: calc(var(--menu-height) - (2*var(--menu-margin)));
    }

    .jobjump-menu-subitem {
        display:block;
    }

    .jobjump-menu-subitem > p {
        line-height: 10px;
    }

    .jobjump-menu-subitem > p > a {
        font-size: 14px;
        color: var(--primary-color);
        cursor: pointer;
    }

    .jobjump-menu-subitem > p > a:hover { 
        color: var(--primary-color);
        text-decoration: underline;
    }

    .hide-dropdown {
        display: none !important;
    }

    .jobjump-menu-popout {
        display: block;
        position: absolute;
        z-index: 100;
        width:200px;
    }

    .jobjump-menu-popout.jobjump-menu-popout-lg {
        width: 350px;
    }

    .jobjump-menu-rhs * .jobjump-menu-popout {
        right: 0;
    }

    .jobjump-submenu {
        padding-top: var(--spacing-32) !important;
    }
}

@media not screen and (min-width: 1220px) {
    :root {
        --menu-height: 96px;
        --menu-margin: 8px;
        --menu-line-height: 25px;
        --logo-height: 80px;
    }

    .jobjump-full-nav {
        display: none;
    }
    .jobjump-mobile-nav {
        display: block;
    }
    .jobjump-logo {
        height: var(--logo-height);
        margin-top: var(--menu-margin);
        margin-bottom: var(--menu-margin);
    }
    .jj-menu-item {
        height:var(--menu-height);
        margin-top: calc(calc(var(--menu-margin) * 2 + var(--logo-height) - var(--menu-line-height)) / 2);
        line-height: var(--menu-line-height);
        padding-left:var(--spacing-16);
        padding-right:var(--spacing-16);
        position: relative;
    }

}

.nav-row {
    display:flex;
}

.jobjump-menu-lhs {
    flex: 2 2;
}

.jobjump-menu-rhs {
    flex: 2 2;
}

.jj-menu-item > .menu-text:hover {
    cursor: pointer;
    color: var(--jobjump-black);
    text-decoration: underline;
}

.jobjump-full-nav-dropdown {
    padding-top:var(--spacing-32) !important;
    margin-left: var(--spacing-16);
    margin-right: var(--spacing-16);
    left: 0;
    right: 0;
    position: absolute;
    z-index: 100;
}


:root {
  --row-width: 1568px;
  --md-min-width: 640px;
  --lg-min-width: 1024px;
}

.jj-row {
    display: flex;
    flex-flow: row wrap;
    max-width: 1568px;
    max-width: var(--row-width);
    margin-right: auto;
    margin-left: auto;
  }
  
  .jj-row.expanded {
    max-width: none;
  }

  .jj-row.small {
    max-width: 600px;
    margin: auto;
  }

  .jj-row.very-small {
    max-width: 450px;
    margin: auto;
  }
   
  .flex-start {
    justify-content: flex-start;
  }
  
  .center {
    justify-content: center;
  }
  
  .flex-end {
    justify-content: flex-end;
  }
  
  .space-between {
    justify-content: space-between;
  }
  
  .space-around {
    justify-content: space-around;
  }
  
  .space-evenly {
    justify-content: space-evenly;
  }
  
  .align-center {
    align-items: center;
  }
  
  .jj-column {
    flex: 1 1;
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
    box-sizing: border-box;
  }

  .jj-column-no-padding {
    padding-left: 0;
    padding-right: 0;
  }
  
/*  .jj-column > .jj-row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }*/
  
  .jj-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  
  .jj-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  
  .jj-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .jj-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  
  .jj-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  
  .jj-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .jj-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  
  .jj-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  
  .jj-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .jj-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  
  .jj-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  
  .jj-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  @media screen and (min-width: 640px) {
    .jj-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
  
    .jj-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
  
    .jj-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  
    .jj-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  
    .jj-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
  
    .jj-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .jj-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
  
    .jj-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
  
    .jj-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
  
    .jj-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
  
    .jj-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
  
    .jj-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .jj-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
  
    .jj-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
  
    .jj-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  
    .jj-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  
    .jj-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
  
    .jj-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .jj-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
  
    .jj-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
  
    .jj-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
  
    .jj-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
  
    .jj-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
  
    .jj-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
.jobjump-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: auto;
    max-height: 95vh;
    min-height: 65vh;
    z-index: 10000;
}

.jobjump-modal-overlay {
    z-index:999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backdrop-filter: brightness(0.9);
            backdrop-filter: brightness(0.9);
}

.jobjump-modal-sm {
    width: 100%
}

.jobjump-modal-md {
    width: 100%
}

.jobjump-modal-lg {
    width: 100%
}

@media screen and (min-width: 40em) {
    .jobjump-modal-sm {
        width: 33%;
        max-width: calc(var(--row-width) * 0.33);
    }

    .jobjump-modal-md {
        width: 66%;
        max-width: calc(var(--row-width) * 0.66);
    }

    .jobjump-modal-lg {
        width: 100%;
        max-width: var(--row-width);
    }
}


.jobjump-button {
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    border-width: 0px!important;
    font: var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-18)/var(--unnamed-line-spacing-31) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0-36);
    color: var(--jobjump-white);
    text-align: center;
    font: normal normal medium 18px/31px Poppins;
    letter-spacing: 0.36px;
    padding-top:var(--spacing-8);
    padding-bottom: var(--spacing-8);
}

.jobjump-button > h4 {
    color: var(--jobjump-white);
}

.jobjump-button-full-width { 
    width: 100%;
}

.jobjump-button:hover {
    opacity: 0.5 !important;
}

.jobjump-button-active {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
}

.jobjump-button-active.jobjump-button-glowing,
.jobjump-button-active:hover {
    box-shadow: 0px 10px 30px var(--primary-color-50);
}

.jobjump-button-neutral {
    background: var(--jobjump-neutral) 0% 0% no-repeat padding-box;
}

.jobjump-button-neutral.jobjump-button-glowing,
.jobjump-button-neutral:hover {
    box-shadow: 0px 10px 30px var(--jobjump-neutral-50);
}

.jobjump-button-negative {
    background: var(--jobjump-negative) 0% 0% no-repeat padding-box;
}

.jobjump-button-negative.jobjump-button-glowing,
.jobjump-button-negative:hover {
    box-shadow: 0px 10px 30px var(--jobjump-negative-50);
}

.jobjump-button-disabled {
    background: var(--jobjump-grey) 0% 0% no-repeat padding-box;
}

.jobjump-button-text {
    background: transparent;
    padding: 0;
}

.jobjump-button-text.jobjump-button-active {
    color: var(--primary-color);
}

.jobjump-button-text.jobjump-button-neutral {
    color: var(--jobjump-neutral)
}

.jobjump-button-text.jobjump-button-negative {
    color: var(--jobjump-negative)
}

.jobjump-button-text.jobjump-button-disabled {
    color: var(--jobjump-light-grey)
}

.jobjump-button-text:hover {
    box-shadow: unset;
}
.login-form {
    width: 100%;
}

.forgot-password {
    color: black !important;
}
.jobjump-form-select > div:first-of-type, 
.jobjump-form-component {
    width: 100%;
    border: 1px solid var(--jobjump-grey);
    border-radius: 6px 6px 6px 6px;
}

.jobjump-form-component {
    padding: 10px;
}

.jobjump-form-select > div:first-of-type {
    padding: 4px;
}

.jobjump-form-component {
    display:block;
}

.jobjump-label {
    font-size: 16px;
    margin-top: var(--spacing-8);
    margin-bottom: var(--spacing-8);
    display: inline-block;
}

.jobjump-radio-block {
    display:block;
}
.jj-alert {
    border-radius: 6px;
}

.jj-alert-block {
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-16) var(--spacing-16);
}

.jj-alert-negative {
    background-color: var(--jobjump-negative-30);
    color: var(--jobjump-white);
}

.jj-alert-text-only.jj-alert-negative h4 {
    color: var(--jobjump-negative) !important;
}

.jj-alert-active {
    background-color: var(--primary-color-30);
    color: var(--jobjump-white);
}

.jj-alert-text-only.jj-alert-active h4 {
    color: var(--primary-color);
}

.jj-alert-text-only {
    background-color: var(--jobjump-white);
}
.jobjump-base-card {
    background: var(--jobjump-white) 0% 0% no-repeat padding-box;
    box-shadow: 20px 20px 60px #0000001A;
    border-radius: 6px;
    opacity: 1;
    padding: 16px 16px 16px 16px;
}
.det-ack {
    background: var(--jobjump-darker-grey) 0% 0% no-repeat padding-box;
    opacity: 1; 
    color: var(--jobjump-white);
}

.copyright {
    color: white;
    padding: 15px 0 5px;
    background-color: #1f1f1f!important;
    background-color: rgba(0,0,0,.32);
}

.copyright a {
    color: white;
}
.main-container {
    min-height: calc(100vh - 385px);
}
:root {
    --unnamed-font-family-poppins: Poppins;
    --unnamed-font-family-lora: Lora;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-normal: normal;
    --unnamed-text-transform-uppercase: uppercase;
    --unnamed-font-weight-300: 300px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-character-spacing-1-8: 1.8px;
    --unnamed-character-spacing-0-36: 0.36px;

    /* Colours */
    --jobjump-lighter-grey: #F1F5F7;
    --jobjump-darker-grey: #171F2D;
    --jobjump-dark-grey: #909090;
    --jobjump-grey: #DDDFE1;
    --jobjump-light-grey: #EEEEEE;
    --jobjump-neutral-base: 78, 78, 78;
    --jobjump-neutral: rgb(var(--jobjump-neutral-base));
    --jobjump-neutral-10: rgba(var(--jobjump-neutral-base), 0.1);
    --jobjump-white: #FFFFFF;
    --jobjump-black: #222222;
    --primary-color-base: 73, 196, 196;
    --primary-color: rgb(var(--primary-color-base));
    --primary-color-10: rgba(var(--primary-color-base), 0.1);
    --primary-color-30: rgba(var(--primary-color-base), 0.3);
    --primary-color-50: rgba(var(--primary-color-base), 0.5);
    --jobjump-negative-base: 199, 83, 47;
    --jobjump-negative: rgb(var(--jobjump-negative-base));
    --jobjump-negative-50: rgba(var(--jobjump-negative-base), 0.5);
    --jobjump-negative-30: rgba(var(--jobjump-negative-base), 0.3);
    --jobjump-negative-10: rgba(var(--jobjump-negative-base), 0.1);

}

@media screen and (min-width:640px) {
    :root {
        /* Font/text values */
        --meta-text-size: 12px;
        --input-label-size: 16px;
        --normal-font-size: 18px;
        --h5-size: 22px;
        --h5-line-height: 33px;
        --h3-size: 28px;
        --h3-line-height: 42px;
        --h2-size: 44px;
        --h2-line-height: 65px;
        --h1-size: 60px;
        --h1-line-height: 76px;
        --meta-text-line-height: 14px;
        --link-line-height: 21px;
        --data-line-height: 25px;
        --h4-line-height: 27px;
        --normal-text-line-height: 31px;
        --label-line-height: 54px;
    }
}

@media screen and (max-width:639px) {
    :root {
        /* Font/text values - scaled back for smaller screens */
        --meta-text-size: 12px;
        --input-label-size: 16px;
        --normal-font-size: 18px;
        --h5-size: 22px;
        --h5-line-height: 33px;
        --h3-size: 18px;
        --h3-line-height: 27px;
        --h2-size: 30px;
        --h2-line-height: 44px;
        --h1-size: 40px;
        --h1-line-height: 48px;
        --meta-text-line-height: 14px;
        --link-line-height: 21px;
        --data-line-height: 25px;
        --h4-line-height: 27px;
        --normal-text-line-height: 31px;
        --label-line-height: 54px;
    }
}

body {
    font: normal normal normal var(--normal-font-size)/var(--normal-text-line-height) Poppins;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--normal-font-size)/var(--normal-text-line-height) var(--unnamed-font-family-poppins);
    letter-spacing: 0.36px;
    letter-spacing: var(--unnamed-character-spacing-0-36);
    font: normal normal normal 18px/31px Poppins;
    letter-spacing: 0.36px;
    opacity: 1;
}

/* Character Styles */
p {
    font-family: Lora;
    font-family: var(--unnamed-font-family-lora);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: normal;
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--normal-font-size);
    line-height: var(--normal-text-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: rgb(78, 78, 78);
    color: var(--jobjump-neutral);
}
.meta-text-12pts {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: normal;
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--meta-text-size);
    line-height: var(--meta-text-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #909090;
    color: var(--jobjump-dark-grey);
}
h1 {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--h1-size);
    line-height: var(--h1-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #222222;
    color: var(--jobjump-black);
}
h2 {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--h2-size);
    line-height: var(--h2-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #222222;
    color: var(--jobjump-black);
}
h3 {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--h3-size);
    line-height: var(--h3-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #222222;
    color: var(--jobjump-black);
}
h5 {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: 300px;
    font-weight: var(--unnamed-font-weight-300);
    font-size: var(--h5-size);
    line-height: var(--h5-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #222222;
    color: var(--jobjump-black);
}
.button-action {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: normal;
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--normal-font-size);
    line-height: var(--link-line-height);
    letter-spacing: 0.36px;
    letter-spacing: var(--unnamed-character-spacing-0-36);
    color: rgb(73, 196, 196);
    color: var(--primary-color);
}
h6 {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--normal-font-size);
    line-height: var(--h4-line-height);
    letter-spacing: 1.8px;
    letter-spacing: var(--unnamed-character-spacing-1-8);
    color: rgb(73, 196, 196);
    color: var(--primary-color);
    text-transform: uppercase;
    text-transform: var(--unnamed-text-transform-uppercase);
}
h4 {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--normal-font-size);
    line-height: var(--h4-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #222222;
    color: var(--jobjump-black);
}
.data-regular {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: 300px;
    font-weight: var(--unnamed-font-weight-300);
    font-size: var(--input-label-size);
    line-height: var(--data-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #222222;
    color: var(--jobjump-black);
}
.data-bold {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--input-label-size);
    line-height: var(--data-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #222222;
    color: var(--jobjump-black);
}
.input-and-label {
    font-family: Poppins;
    font-family: var(--unnamed-font-family-poppins);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: normal;
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--input-label-size);
    line-height: var(--label-line-height);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #909090;
    color: var(--jobjump-dark-grey);
}
a {
    font: normal normal normal var(--normal-font-size)/var(--link-line-height) Poppins;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--normal-font-size)/var(--link-line-height) var(--unnamed-font-family-poppins);
    letter-spacing: 0.36px;
    letter-spacing: var(--unnamed-character-spacing-0-36);
    color: rgb(73, 196, 196);
    color: var(--primary-color);
    text-decoration: none;
    text-align: left;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.pull-left {
    float: left;
}
.pull-right {
    float: right;
}
.pointer {
    cursor: pointer;
}
.text-white {
    color: #FFFFFF;
    color: var(--jobjump-white);
}
.link-section-heading {
    margin-top: var(--spacing-64);
}
:root {
    --spacing-8: 8px;
    --spacing-16: 16px;
    --spacing-32: 32px;
    --spacing-64: 64px;
    --spacing-128: 128px;
}
.mt-8 {
    margin-top: 8px;
    margin-top: var(--spacing-8);
}
.mt-16 {
    margin-top: 16px;
    margin-top: var(--spacing-16);
}
.mt-32 {
    margin-top: 32px;
    margin-top: var(--spacing-32);
}
.mt-64 {
    margin-top: 64px;
    margin-top: var(--spacing-64);
}
.mt-128 {
    margin-top: 128px;
    margin-top: var(--spacing-128);
}
.ml-8 {
    margin-left: 8px;
    margin-left: var(--spacing-8);
}
.ml-16 {
    margin-left: 16px;
    margin-left: var(--spacing-16);
}
.ml-32 {
    margin-left: 32px;
    margin-left: var(--spacing-32);
}
.ml-64 {
    margin-left: 64px;
    margin-left: var(--spacing-64);
}
.ml-128 {
    margin-left: 128px;
    margin-left: var(--spacing-128);
}
.mr-8 {
    margin-right: 8px;
    margin-right: var(--spacing-8);
}
.mr-16 {
    margin-right: 16px;
    margin-right: var(--spacing-16);
}
.mr-32 {
    margin-right: 32px;
    margin-right: var(--spacing-32);
}
.mr-64 {
    margin-right: 64px;
    margin-right: var(--spacing-64);
}
.mr-128 {
    margin-right: 128px;
    margin-right: var(--spacing-128);
}
.mb-8 {
    margin-bottom: 8px;
    margin-bottom: var(--spacing-8);
}
.mb-16 {
    margin-bottom: 16px;
    margin-bottom: var(--spacing-16);
}
.mb-32 {
    margin-bottom: 32px;
    margin-bottom: var(--spacing-32);
}
.mb-64 {
    margin-bottom: 64px;
    margin-bottom: var(--spacing-64);
}
.mb-128 {
    margin-bottom: 128px;
    margin-bottom: var(--spacing-128);
}
.m-8 {
    margin: 8px 8px 8px 8px;
    margin: var(--spacing-8) var(--spacing-8) var(--spacing-8) var(--spacing-8)
}
.m-16 {
    margin: 16px 16px 16px 16px;
    margin: var(--spacing-16) var(--spacing-16) var(--spacing-16) var(--spacing-16)
}
.m-32 {
    margin: 32px 32px 32px 32px;
    margin: var(--spacing-32) var(--spacing-32) var(--spacing-32) var(--spacing-32)
}
.m-64 {
    margin: 64px 64px 64px 64px;
    margin: var(--spacing-64) var(--spacing-64) var(--spacing-64) var(--spacing-64)
}
.m-128 {
    margin: 128px 128px 128px 128px;
    margin: var(--spacing-128) var(--spacing-128) var(--spacing-128) var(--spacing-128)
}
.pt-8 {
    padding-top: 8px;
    padding-top: var(--spacing-8);
}
.pt-16 {
    padding-top: 16px;
    padding-top: var(--spacing-16);
}
.pt-32 {
    padding-top: 32px;
    padding-top: var(--spacing-32);
}
.pt-64 {
    padding-top: 64px;
    padding-top: var(--spacing-64);
}
.pt-128 {
    padding-top: 128px;
    padding-top: var(--spacing-128);
}
.pl-8 {
    padding-left: 8px;
    padding-left: var(--spacing-8);
}
.pl-16 {
    padding-left: 16px;
    padding-left: var(--spacing-16);
}
.pl-32 {
    padding-left: 32px;
    padding-left: var(--spacing-32);
}
.pl-64 {
    padding-left: 64px;
    padding-left: var(--spacing-64);
}
.pl-128 {
    padding-left: 128px;
    padding-left: var(--spacing-128);
}
.pr-8 {
    padding-right: 8px;
    padding-right: var(--spacing-8);
}
.pr-16 {
    padding-right: 16px;
    padding-right: var(--spacing-16);
}
.pr-32 {
    padding-right: 32px;
    padding-right: var(--spacing-32);
}
.pr-64 {
    padding-right: 64px;
    padding-right: var(--spacing-64);
}
.pr-128 {
    padding-right: 128px;
    padding-right: var(--spacing-128);
}
.pb-8 {
    padding-bottom: 8px;
    padding-bottom: var(--spacing-8);
}
.pb-16 {
    padding-bottom: 16px;
    padding-bottom: var(--spacing-16);
}
.pb-32 {
    padding-bottom: 32px;
    padding-bottom: var(--spacing-32);
}
.pb-64 {
    padding-bottom: 64px;
    padding-bottom: var(--spacing-64);
}
.pb-128 {
    padding-bottom: 128px;
    padding-bottom: var(--spacing-128);
}
.p-8 {
    padding: 8px 8px 8px 8px;
    padding: var(--spacing-8) var(--spacing-8) var(--spacing-8) var(--spacing-8)
}
.p-16 {
    padding: 16px 16px 16px 16px;
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-16) var(--spacing-16)
}
.p-32 {
    padding: 32px 32px 32px 32px;
    padding: var(--spacing-32) var(--spacing-32) var(--spacing-32) var(--spacing-32)
}
.p-64 {
    padding: 64px 64px 64px 64px;
    padding: var(--spacing-64) var(--spacing-64) var(--spacing-64) var(--spacing-64)
}
.p-128 {
    padding: 128px 128px 128px 128px;
    padding: var(--spacing-128) var(--spacing-128) var(--spacing-128) var(--spacing-128)
}
.block {
    display: block;
}


@media screen and (min-width:640px) {
  .Splash {
    margin-left: var(--spacing-64);
    margin-right: var(--spacing-64);
  }
}

@media screen and (max-width:639px) {
  .Splash {
    margin-left: var(--spacing-16);
    margin-right: var(--spacing-16);
  }
}

.hero-video-text {
  text-shadow: 10px 7px 16px #7f7f7f4c;
  color: var(--jobjump-light-grey);
  text-align: center;
  display: block;
}

.Splash {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background: #000000B3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    height: calc(100vh - var(--menu-height) - 60px);
    overflow: hidden;
}

.video-container {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}

.video-container * img,
.video-container * video {
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  z-index:2;
}

.video-container * img {
  opacity: 0.8;
}


.video-float-mt-5 {
    position: absolute;
    top: calc(50% - 5vh);
    width: 100%;
    z-index: 10;
}
.jobjump-decorated-row-diagonal-line {
    background: transparent -webkit-gradient(linear, left top, right top, from(var(--primary-color)), to(var(--primary-color-30))) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(90deg, var(--primary-color) 0%, var(--primary-color-30) 100%) 0% 0% no-repeat padding-box;
}

@media screen and (min-width:640px) {
    .jobjump-decorated-row-diagonal-line {
        -webkit-clip-path: polygon(0 0, 100% 24%, 100% 500%, 0% 500%);
                clip-path: polygon(0 0, 100% 24%, 100% 500%, 0% 500%);
        margin-top:calc(var(--spacing-64) * -1);
        padding-top: calc(var(--spacing-128) + var(--spacing-64) - 20%);
        padding-bottom:var(--spacing-16);
        margin-bottom: 10%;

    }
    .diagonal-wrapper {
        -webkit-transform: translate(0, 20%);
                transform: translate(0, 20%);
        margin:auto;
    }
}

@media screen and (max-width:639px) {
    .jobjump-decorated-row-diagonal-line {
        -webkit-clip-path: polygon(0 0, 100% 5%, 100% 500%, 0% 500%);
                clip-path: polygon(0 0, 100% 5%, 100% 500%, 0% 500%);
        margin-top:calc(var(--spacing-16) * -1);
        padding-top: calc(var(--spacing-32) + var(--spacing-16) - 20%);
        padding-bottom:var(--spacing-8);
        margin-bottom: 40%;
    }
    .diagonal-wrapper {
        -webkit-transform: translate(0, 5%);
                transform: translate(0, 5%);
        margin:auto;
    }
}

.jobjump-decorated-row-diagonal-line.no-negative-margin {
    margin-top: 0px !important;
}


.jobjump-svg-fill {
    fill: var(--primary-color);
}

.jobjump-svg-stroke {
    stroke: var(--primary-color);
}
.circle-content {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

.jobjump-decorated-row-circles {
    position:relative;
}
@media screen and (min-width: 760px) {
    .confetti {
        display: block;
    }
}

@media not screen and (min-width: 760px) {
    .confetti {
        display: none;
    }
}

.confetti-outer {
    position: absolute;
    top: 50%;

}

.confetti-outer-lhs {
    right: 0%;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
}

.confetti-outer-rhs {
    left:0%;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
}

.compact-confetti {
    max-width: 85px;
}

.confetti-lhs {
    max-width: 311px;
}

.confetti-rhs {
    max-width: 223px;
}

.confetti-wrapper {
    position:relative;
}
.jobjump-card-image {
    box-shadow: 20px 20px 60px #0000001A;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    width: 100%;
    height: 300px;
}

.jobjump-image-card-disabled {
    opacity: 0.5;
}

.jobjump-full-image-card img {
    height: 100% !important;
}

.jobjump-card-image.cover {
    object-fit: cover;
}

.jobjump-card-image.contain {
    object-fit: contain;
}

.jobjump-image-card:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    z-index: 50;
    box-shadow: 20px 20px 60px #00000036;
    -webkit-transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
}

.jobjump-image-card {
    -webkit-transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
}

.image-card-selected {
    background-color: var(--primary-color);
}

.image-card-selected-glow,
.image-card-selected-glow:hover {
    box-shadow: 0px 0px 30px 10px var(--primary-color);
}

.image-card-selected h4 {
    color:white;
}

.image-card-no-padding {
    padding: 0px 0px 0px 0px !important;
}
.jobjump-clipped-5-left,
.jobjump-clipped-5-right {
    -webkit-filter: drop-shadow(-1px 6px 3px #0000001A);
            filter: drop-shadow(-1px 6px 3px #0000001A);
    -webkit-transition: -webkit-filter 0.2s ease-in-out;
    transition: -webkit-filter 0.2s ease-in-out;
    transition: filter 0.2s ease-in-out;
    transition: filter 0.2s ease-in-out, -webkit-filter 0.2s ease-in-out;
    position: relative;
}

.jobjump-clipped-5-left > img {
    -webkit-clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
            clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
    -webkit-transition: -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
    transition: -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
    transition: clip-path 0.2s, transform 0.2s ease-in-out;
    transition: clip-path 0.2s, transform 0.2s ease-in-out, -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
}

.jobjump-clipped-5-right > img {
    -webkit-clip-path: polygon(0 0, 100% 5%, 100% 95%, 0 100%);
            clip-path: polygon(0 0, 100% 5%, 100% 95%, 0 100%);
    -webkit-transition: -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
    transition: -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
    transition: clip-path 0.2s, transform 0.2s ease-in-out;
    transition: clip-path 0.2s, transform 0.2s ease-in-out, -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
}


.jobjump-clipped-clickable {
    cursor: pointer;
}

.play-icon:hover,
.jobjump-clipped-clickable:hover {
    -webkit-filter: drop-shadow(-1px 6px 3px #00000036);
            filter: drop-shadow(-1px 6px 3px #00000036);
    -webkit-transition: -webkit-filter 0.2s ease-in-out;
    transition: -webkit-filter 0.2s ease-in-out;
    transition: filter 0.2s ease-in-out;
    transition: filter 0.2s ease-in-out, -webkit-filter 0.2s ease-in-out;
}

.jobjump-clipped-clickable > img:hover {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-transition: -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
    transition: -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
    transition: clip-path 0.2s, transform 0.2s ease-in-out;
    transition: clip-path 0.2s, transform 0.2s ease-in-out, -webkit-clip-path 0.2s, -webkit-transform 0.2s ease-in-out;
}

.play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-filter: drop-shadow(-1px 6px 3px #0000001A);
            filter: drop-shadow(-1px 6px 3px #0000001A);
}
.video-start {
    margin-top:15%;
}

.vertical-text {
    display:flex;
    flex-direction: row;
    align-items:center;
}
.jobjump-video-modal {
    background: unset;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: auto;
    z-index: 10000;
}

.video-close-button {
    color: white;
    cursor: pointer;
}
.jobjump-breadcrumb > a {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: center;
    font: normal normal normal 12px/14px Poppins;
    letter-spacing: 0px;
}

.page-hero-wrapper {
    max-width: 600px;
    margin: auto;
}
.black-bar {
    background-color: #222!important;
    font-weight: 700!important;
    color: white;
    text-transform: uppercase!important;
    text-align: center;
    font-size: 18px;
}
.jobjump-row-card.jobjump-row-card-selectable:hover {
    cursor: pointer;
    background-color: var(--jobjump-lighter-grey);
}

.jobjump-row-card.jobjump-row-card-selected {
    background-color: var(--jobjump-lighter-grey);
    border-left: solid 11px var(--primary-color);
}

.jobjump-row-card > .jj-row {
    width: 100% !important;
}
.career-chip {
    background: var(--jobjump-light-grey);
    padding: 2px 8px 2px 2px;
    border-radius: 25px;
    cursor: pointer;
}

.squareAlert {
    border-radius: 0px !important;
}
.atar-table thead th, .atar-table tbody td {
    border: 1px solid black !important;
}

.hoverable-text {
    cursor: pointer;
    display: inline-block;
    border-bottom:1px dotted black;
}
.fill-icon {
    fill: black;
}

.circle-icon {
    border: solid 1px;
    border-radius: 15px;
}
.jj-rowcard-cell {
    flex: 1 1;
    padding-left: var(--spacing-8);
    padding-right: var(--spacing-8);
    box-sizing: border-box;
}

.jj-cell-2 {
    flex: 0 0 4.166666%;
    max-width: 4.166666%;
}

.jj-cell-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.jj-cell-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}

.jj-cell-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.jj-cell-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
}

.jj-cell-6 {
    flex: 0 0 25%;
    max-width: 25%;
}

.jj-cell-7 {
    flex: 0 0 29.16666%;
    max-width: 29.16666%;
}

.jj-cell-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.jj-cell-8 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}

.jj-cell-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.jj-cell-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
}

.jj-cell-12 {
    flex: 0 0 50%;
    max-width: 50%;
}

.jj-cell-13 {
    flex: 0 0 54.16666%;
    max-width: 54.16666%;
}

.jj-cell-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.jj-cell-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
}

.jj-cell-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.jj-cell-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
}

.jj-cell-18 {
    flex: 0 0 75%;
    max-width: 75%;
}

.jj-cell-19 {
    flex: 0 0 79.16666%;
    max-width: 79.16666%;
}

.jj-cell-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.jj-cell-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
}

.jj-cell-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.jj-cell-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
}

.jj-cell-24 {
    flex: 0 0 100%;
    max-width: 100%;
}

.react-numeric-input {
    height: 55px !important;
    font-size:35px !important;
}

.react-numeric-input > input {
    width: 160px !important;
    height: 55px !important;
}
.state-NSW {
    background: #9fbff1 !important;
}

.state-VIC {
    background: #9ae09a !important;
}

.state-QLD {
    background: #f1a2a2 !important;
}

.state-tafe {
    background: #e8e86b !important;
}

.ca-question {
    display:inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #CCC;
    border-radius: 3px;
    padding: 5px;
}

.pref-card {
    cursor: move;
}
.jobjump-news-date {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/var(--unnamed-line-spacing-14) Montserrat;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: left;
    font: normal normal normal 10px/14px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
}

.jobjump-news-lead {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-22)/30px var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--primary-color);
    text-align: left;
    font: normal normal 300 22px/30px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}

.jobjump-news-body {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/20px var(--unnamed-font-family-lora);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: left;
    font: normal normal normal 14px/20px Lora;
    letter-spacing: 0px; 
}

.truncate-lines-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-lines-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jobjump-news-img img {
  height: 350px;
  width: 100%;
  min-width: 100%;
  object-fit: cover;
}
.hidden-resume-section {
    opacity: 0.5;
}
.resume-card-head {
    color: white;
    font-size: 2rem;
    font-weight: 600;
}
.goals {
    background: #acacb4 !important;
}
.skills {
    background: #edceb9!important;
}
.education {
    background: #a28a71 !important;
}
.employment {
    background: #4a4b65 !important;
}
.tertiary {
    background: #8fbddc !important;
}
.awards {
    background: #808c8c !important;
}
.details {
    background: #bb86bc !important;
}

.jobjump-secondary-menu {
    background: var(--jobjump-white) 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 60px #8F8F8F1A;
    border: 6px solid #F2F2F2;
    border-radius: 10px 10px 0px 0px;
    max-width: 1264px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: var(--spacing-128);
}

.jobjump-secondary-menu-title-row {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
    display: flex;
}

.jobjump-secondary-menu-title {
    flex: 2 1;
}

.jobjump-secondary-menu-secondary {
    margin-left:auto;
    margin-right: var(--spacing-32);
    order: 2;
}

.jobjump-secondary-menu-item-row {
    display:flex
}

.jobjump-secondary-menu-item-row .jobjump-secondary-menu-item:not(:first-child) {
    border-left: solid 2px #F2F2F2;
}

.jobjump-secondary-menu-item {
    flex-grow: 1;
    max-width: 158px;
}
.jobjump-secondary-menu-button {
    cursor: pointer;
    text-align: center;
    border-top: solid 2px #F2F2F2;
    border-bottom: none;
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
    height: 100%;
}

.jobjump-secondary-menu-button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background: #fff;
    box-shadow: 0px 20px 50px 0px rgb(26 26 26 / 9%);
    -webkit-transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
}
.jobjump-secondary-menu-item:hover > .jobjump-secondary-dropdown {
    -webkit-transform: scale(1);
            transform: scale(1);
}

.jobjump-secondary-menu-dull > .jobjump-secondary-menu-icon > svg * path,
.jobjump-secondary-menu-dull > .jobjump-secondary-menu-icon > svg * rect {
    fill: var(--jobjump-grey);
}

.jobjump-secondary-menu-highlight > .jobjump-secondary-menu-icon > svg * path,
.jobjump-secondary-menu-highlight > .jobjump-secondary-menu-icon > svg * rect {
    color: var(--primary-color);
}

.jobjump-secondary-menu-icon {
    padding-bottom: var(--spacing-16);
}

.jobjump-secondary-menu-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: center;
    font: normal normal normal 12px/14px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}

.jobjump-secondary-dropdown-show {
    display:block;
}

.jobjump-secondary-dropdown-hide {
    display:none;
}

.jobjump-secondary-dropdown {
    position: absolute;
    margin-top: 103px;
    z-index: 10;
    text-align: left;
}

.jobjump-secondary-menu-item.active {
    border-bottom: solid 5px var(--primary-color);
}
.csvlink {
    font: unset;
}
.school-registration-page {
    max-width: 600px;
    margin: auto;
}
.progressbar {
    counter-reset: step;
    display:flex;
    justify-content: center;
}
.progressbar li {
    list-style-type: none;
    position: relative;
    text-align: center;
    color: var(--jobjump-grey);
    margin-left:var(--spacing-32);
    margin-right:var(--spacing-32);
}
.progressbar li:first-child {
    margin-left:0px;
}
.progressbar li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 20px;
    border: 2xp solid var(--jobjump-grey);
    display: block;
    text-align: center;
    border-radius: 50%;
    margin: 0 auto 0px auto;
    background-color: white;
}
.progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: var(--jobjump-grey);
    top: 15px;
    left: -100%;
    z-index: -1;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: var(--primary-color);
}
.progressbar li.active:before {
    border-color: var(--primary-color-50);
}
.progressbar li.active + li:after {
    background-color: var(--primary-color-50);
}
.jobjump-expand-row-hide {
    display:none
}

.jobjump-expand-row-show {
    display: block;
}
.react-time-picker {
    display: block !important;
}

.react-time-picker__wrapper {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-time-picker__inputGroup__input {
    color: #495057;
}
.videoLink {
    cursor: pointer;
    margin-bottom: 5px;
}
.white-box {
    background-color: white !important;
}

.cursor-pointer {
    cursor: pointer;
}
.school-homepage-video {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 65vh;
    background: #000;
    overflow: hidden;
    background:black;
}

.school-video-container {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}

.school-video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.video-float {
    position: absolute;
    top: calc(50% - 5vh);
    width: 100%;
    z-index: 10;
}

.school-news-card {
    background: unset !important;
    border-radius: 0px !important;
    margin-left: 7px;
    margin-right: 7px;
    height: 20vh;
}

.school-news-card .card-header {
    font-size: 18px;
    font-weight: 700!important;
    text-transform: uppercase!important;
    text-align: center;
    background-color: #222222;
    color: white;
}

.school-news-card .card-body {
    background-color: white;
    overflow-y:auto;
}

.slick-track {
    display: flex !important;
}
  
.slick-slide {
    height: auto;
}

.careers-noticeboard-section {
    width: 65vw;
}

@media screen and (max-width:760px) {
    .careers-noticeboard-section {
        width: 100vw;
    }
}
.jobjump-noticeboard-card {
    width: 100%;
    background: var(--jobjump-lighter-grey) 0% 0% no-repeat padding-box;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    border-left: solid 11px var(--primary-color);
    padding-left: var(--spacing-32);
}

.jobjump-noticeboard-date {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/16px Montserrat;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: left;
    font: normal normal normal 12px/16px Montserrat;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
}
.modal-90w {
    min-width: 60% !important;
}

.dtborder {
   position:relative; /*important! */
   border:2px solid #000;
   background: #f9f9f9;
   padding:30px;
   border-radius: 0px;
}
.dtborder:before {
  content:"";
  position: absolute;
  /*set margins in other words its positioning our added pseudo content*/
  top:5px;
  bottom:5px;
  left:5px;
  right:5px;
  border:2px solid #999;
  border-radius: 0px;
}

.print-cert {
    height: 100%;
}

.print-cert h2 {
    font-size: 4rem;
}

.print-cert h3 {
    font-size: 3rem;
}

.print-cert p {
    font-size: 1.7rem;
}

.cursive-font {
    font-family: 'Parisienne', cursive;
    font-weight: bold;
}
.upload-zone {
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    text-align: center;
    cursor: pointer;
    -webkit-transition: border .24s ease-in-out;
    transition: border .24s ease-in-out;
    padding: var(--spacing-32); 
}

.background-upload > .upload-zone {
    height: calc(100% - 45px);
}
.career-score-section {
    max-width: 500px;
    margin: auto;
}

.cwiz-svg {
    fill: var(--primary-color);
}

.selected-quiz-image {
    height:350px;
    background-color: var(--primary-color);
}
.unselected-quiz-image {
    height:350px;
}

.completed-quiz-tile {
    background-color: var(--primary-color-50);
}

.cwiz-option-card {
    height: 470px;
}

.score-col {
    font-size: 18px;
    font-weight: 500;
}

.cwiz-mood-text-tile {
    position:relative;
}

.cwiz-mood-text-tile img {
    opacity: 0.3;
}

.cwiz-mood-text {
    position:absolute;
    color: var(--jobjump-darker-grey);
    -webkit-filter: drop-shadow(5px 5px 10px #999999);
            filter: drop-shadow(5px 5px 10px #999999);
}

.cwiz-mood-text.upper {
    top: 64px;
    left: 48px;
}

.cwiz-mood-text.lower {
    bottom: 64px;
    right: 48px;
}
.jobjump-card-button {
    -webkit-transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    cursor: pointer;
}

.jobjump-card-button:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    z-index: 50;
    box-shadow: 20px 20px 60px #00000036;
    -webkit-transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out, -webkit-transform 0.2s;
}

.jobjump-card-button.jobjump-card-button-active:hover {
    background-color: var(--primary-color-10);
}

.jobjump-card-button.jobjump-card-button-negative:hover {
    background-color: var(--negative-color-10);
}

.jobjump-card-button.jobjump-card-button-selected.jobjump-card-button-active {
    background-color: var(--primary-color-50);
}

.jobjump-card-button.jobjump-card-button-selected.jobjump-card-button-negative {
    background-color: var(--negative-color-50);
}
.filter-grid-select-selected,
.filter-grid-select-unselected {
    height: 240px;
}

.filter-grid-select-selected .jobjump-card-image,
.filter-grid-select-unselected  .jobjump-card-image {
    height: 140px;
}

.filter-grid-select-selected {
    background-color: var(--primary-color);
}
.filter-grid-select-selected h4 {
    color:white;
}

.careerMultiValue {
    display:flex;
    padding: var(--spacing-8);
    background-color: var(--jobjump-light-grey);
    border-radius: 20px;
    font-size: 16px;
    margin: 2px;
}

.multiValueImage img {
    width: var(--spacing-32);
    height: var(--spacing-32);
    object-fit: cover;
    -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%);
}

.careerOption {
    display: flex;
} 

.hoverable-text {
    border-bottom:1px dotted black;
}

.hoverable {
    cursor: pointer;
    display: inline-block;
}
.deactivated-icon {
    color: var(--jobjump-grey)
}
 
.active-icon {
    color: black;
}
.resume-template-card {
    padding: 8px 8px 8px 8px !important;
    height: 200px;
}

.bitter {
    font-family: 'Bitter', serif;
}

.indieFlower {
    font-family: 'Indie Flower', cursive;
}

.inter {
    font-family: 'Inter', sans-serif;
}

.lato {
    font-family: 'Lato', sans-serif;
}

.poppins {
    font-family: 'Poppins', sans-serif;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.shadowsIntoLight {
    font-family: 'Shadows Into Light', cursive;
}

.abril {
font-family: 'Abril Fatface', cursive;
}

.architect {
font-family: 'Architects Daughter', cursive;
}
.dancing {
font-family: 'Dancing Script', cursive;
     
}
.dosis {
font-family: 'Dosis', sans-serif;

}

.inconsolata {
font-family: 'Inconsolata', monospace;

}

.italianno {
font-family: 'Italianno', cursive;
}
.open-sans {
font-family: 'Open Sans Condensed', sans-serif;

}
.pacifico {
font-family: 'Pacifico', cursive;

}
.playfair {
font-family: 'Playfair Display', serif;

}
.satisfy {
font-family: 'Satisfy', cursive;

}
.teko {
font-family: 'Teko', sans-serif;
}
.jobjump-badge {
    display: inline;
    border: none;
    cursor: default;
    height: 36px;
    outline: none;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
    font-size: 14px;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
}

.jobjump-badge-active {
    background: var(--primary-color);
    color: var(--jobjump-white);
}

.jobjump-badge-negative {
    background: var(--jobjump-negative);
    color: var(--jobjump-white);
}
