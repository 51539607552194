.career-score-section {
    max-width: 500px;
    margin: auto;
}

.cwiz-svg {
    fill: var(--primary-color);
}

.selected-quiz-image {
    height:350px;
    background-color: var(--primary-color);
}
.unselected-quiz-image {
    height:350px;
}

.completed-quiz-tile {
    background-color: var(--primary-color-50);
}

.cwiz-option-card {
    height: 470px;
}

.score-col {
    font-size: 18px;
    font-weight: 500;
}

.cwiz-mood-text-tile {
    position:relative;
}

.cwiz-mood-text-tile img {
    opacity: 0.3;
}

.cwiz-mood-text {
    position:absolute;
    color: var(--jobjump-darker-grey);
    filter: drop-shadow(5px 5px 10px #999999);
}

.cwiz-mood-text.upper {
    top: 64px;
    left: 48px;
}

.cwiz-mood-text.lower {
    bottom: 64px;
    right: 48px;
}