.jj-alert {
    border-radius: 6px;
}

.jj-alert-block {
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-16) var(--spacing-16);
}

.jj-alert-negative {
    background-color: var(--jobjump-negative-30);
    color: var(--jobjump-white);
}

.jj-alert-text-only.jj-alert-negative h4 {
    color: var(--jobjump-negative) !important;
}

.jj-alert-active {
    background-color: var(--primary-color-30);
    color: var(--jobjump-white);
}

.jj-alert-text-only.jj-alert-active h4 {
    color: var(--primary-color);
}

.jj-alert-text-only {
    background-color: var(--jobjump-white);
}