.state-NSW {
    background: #9fbff1 !important;
}

.state-VIC {
    background: #9ae09a !important;
}

.state-QLD {
    background: #f1a2a2 !important;
}

.state-tafe {
    background: #e8e86b !important;
}

.ca-question {
    display:inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #CCC;
    border-radius: 3px;
    padding: 5px;
}

.pref-card {
    cursor: move;
}