.jobjump-video-modal {
    background: unset;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    z-index: 10000;
}

.video-close-button {
    color: white;
    cursor: pointer;
}