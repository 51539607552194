@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --unnamed-font-family-poppins: Poppins;
    --unnamed-font-family-lora: Lora;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-normal: normal;
    --unnamed-text-transform-uppercase: uppercase;
    --unnamed-font-weight-300: 300px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-character-spacing-1-8: 1.8px;
    --unnamed-character-spacing-0-36: 0.36px;

    /* Colours */
    --jobjump-lighter-grey: #F1F5F7;
    --jobjump-darker-grey: #171F2D;
    --jobjump-dark-grey: #909090;
    --jobjump-grey: #DDDFE1;
    --jobjump-light-grey: #EEEEEE;
    --jobjump-neutral-base: 78, 78, 78;
    --jobjump-neutral: rgb(var(--jobjump-neutral-base));
    --jobjump-neutral-10: rgba(var(--jobjump-neutral-base), 0.1);
    --jobjump-white: #FFFFFF;
    --jobjump-black: #222222;
    --primary-color-base: 73, 196, 196;
    --primary-color: rgb(var(--primary-color-base));
    --primary-color-10: rgba(var(--primary-color-base), 0.1);
    --primary-color-30: rgba(var(--primary-color-base), 0.3);
    --primary-color-50: rgba(var(--primary-color-base), 0.5);
    --jobjump-negative-base: 199, 83, 47;
    --jobjump-negative: rgb(var(--jobjump-negative-base));
    --jobjump-negative-50: rgba(var(--jobjump-negative-base), 0.5);
    --jobjump-negative-30: rgba(var(--jobjump-negative-base), 0.3);
    --jobjump-negative-10: rgba(var(--jobjump-negative-base), 0.1);

}

@media screen and (min-width:640px) {
    :root {
        /* Font/text values */
        --meta-text-size: 12px;
        --input-label-size: 16px;
        --normal-font-size: 18px;
        --h5-size: 22px;
        --h5-line-height: 33px;
        --h3-size: 28px;
        --h3-line-height: 42px;
        --h2-size: 44px;
        --h2-line-height: 65px;
        --h1-size: 60px;
        --h1-line-height: 76px;
        --meta-text-line-height: 14px;
        --link-line-height: 21px;
        --data-line-height: 25px;
        --h4-line-height: 27px;
        --normal-text-line-height: 31px;
        --label-line-height: 54px;
    }
}

@media screen and (max-width:639px) {
    :root {
        /* Font/text values - scaled back for smaller screens */
        --meta-text-size: 12px;
        --input-label-size: 16px;
        --normal-font-size: 18px;
        --h5-size: 22px;
        --h5-line-height: 33px;
        --h3-size: 18px;
        --h3-line-height: 27px;
        --h2-size: 30px;
        --h2-line-height: 44px;
        --h1-size: 40px;
        --h1-line-height: 48px;
        --meta-text-line-height: 14px;
        --link-line-height: 21px;
        --data-line-height: 25px;
        --h4-line-height: 27px;
        --normal-text-line-height: 31px;
        --label-line-height: 54px;
    }
}

body {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--normal-font-size)/var(--normal-text-line-height) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0-36);
    font: normal normal normal 18px/31px Poppins;
    letter-spacing: 0.36px;
    opacity: 1;
}

/* Character Styles */
p {
    font-family: var(--unnamed-font-family-lora);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--normal-font-size);
    line-height: var(--normal-text-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-neutral);
}
.meta-text-12pts {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--meta-text-size);
    line-height: var(--meta-text-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
}
h1 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--h1-size);
    line-height: var(--h1-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-black);
}
h2 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--h2-size);
    line-height: var(--h2-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-black);
}
h3 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--h3-size);
    line-height: var(--h3-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-black);
}
h5 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-300);
    font-size: var(--h5-size);
    line-height: var(--h5-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-black);
}
.button-action {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--normal-font-size);
    line-height: var(--link-line-height);
    letter-spacing: var(--unnamed-character-spacing-0-36);
    color: var(--primary-color);
}
h6 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--normal-font-size);
    line-height: var(--h4-line-height);
    letter-spacing: var(--unnamed-character-spacing-1-8);
    color: var(--primary-color);
    text-transform: var(--unnamed-text-transform-uppercase);
}
h4 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--normal-font-size);
    line-height: var(--h4-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-black);
}
.data-regular {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-300);
    font-size: var(--input-label-size);
    line-height: var(--data-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-black);
}
.data-bold {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--input-label-size);
    line-height: var(--data-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-black);
}
.input-and-label {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--input-label-size);
    line-height: var(--label-line-height);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
}
a {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--normal-font-size)/var(--link-line-height) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0-36);
    color: var(--primary-color);
    text-decoration: none;
    text-align: left;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.pull-left {
    float: left;
}
.pull-right {
    float: right;
}
.pointer {
    cursor: pointer;
}
.text-white {
    color: var(--jobjump-white);
}
.link-section-heading {
    margin-top: var(--spacing-64);
}