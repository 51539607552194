.upload-zone {
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    text-align: center;
    cursor: pointer;
    transition: border .24s ease-in-out;
    padding: var(--spacing-32); 
}
