.jobjump-form-select > div:first-of-type, 
.jobjump-form-component {
    width: 100%;
    border: 1px solid var(--jobjump-grey);
    border-radius: 6px 6px 6px 6px;
}

.jobjump-form-component {
    padding: 10px;
}

.jobjump-form-select > div:first-of-type {
    padding: 4px;
}

.jobjump-form-component {
    display:block;
}

.jobjump-label {
    font-size: 16px;
    margin-top: var(--spacing-8);
    margin-bottom: var(--spacing-8);
    display: inline-block;
}

.jobjump-radio-block {
    display:block;
}