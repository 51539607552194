.careerMultiValue {
    display:flex;
    padding: var(--spacing-8);
    background-color: var(--jobjump-light-grey);
    border-radius: 20px;
    font-size: 16px;
    margin: 2px;
}

.multiValueImage img {
    width: var(--spacing-32);
    height: var(--spacing-32);
    object-fit: cover;
    clip-path: circle(50% at 50% 50%);
}

.careerOption {
    display: flex;
} 
