@media screen and (min-width: 760px) {
    .confetti {
        display: block;
    }
}

@media not screen and (min-width: 760px) {
    .confetti {
        display: none;
    }
}

.confetti-outer {
    position: absolute;
    top: 50%;

}

.confetti-outer-lhs {
    right: 0%;
    transform: translate(0%, -50%);
}

.confetti-outer-rhs {
    left:0%;
    transform: translate(0%, -50%);
}

.compact-confetti {
    max-width: 85px;
}

.confetti-lhs {
    max-width: 311px;
}

.confetti-rhs {
    max-width: 223px;
}

.confetti-wrapper {
    position:relative;
}