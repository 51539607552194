.jobjump-noticeboard-card {
    width: 100%;
    background: var(--jobjump-lighter-grey) 0% 0% no-repeat padding-box;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    border-left: solid 11px var(--primary-color);
    padding-left: var(--spacing-32);
}

.jobjump-noticeboard-date {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/16px Montserrat;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: left;
    font: normal normal normal 12px/16px Montserrat;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
}