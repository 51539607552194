.jobjump-clipped-5-left,
.jobjump-clipped-5-right {
    filter: drop-shadow(-1px 6px 3px #0000001A);
    transition: filter 0.2s ease-in-out;
    position: relative;
}

.jobjump-clipped-5-left > img {
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
    transition: clip-path 0.2s, transform 0.2s ease-in-out;
}

.jobjump-clipped-5-right > img {
    clip-path: polygon(0 0, 100% 5%, 100% 95%, 0 100%);
    transition: clip-path 0.2s, transform 0.2s ease-in-out;
}


.jobjump-clipped-clickable {
    cursor: pointer;
}

.play-icon:hover,
.jobjump-clipped-clickable:hover {
    filter: drop-shadow(-1px 6px 3px #00000036);
    transition: filter 0.2s ease-in-out;
}

.jobjump-clipped-clickable > img:hover {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transform: scale(1.05);
    transition: clip-path 0.2s, transform 0.2s ease-in-out;
}

.play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(-1px 6px 3px #0000001A);
}