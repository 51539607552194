.jobjump-card-button {
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
    cursor: pointer;
}

.jobjump-card-button:hover {
    transform: scale(1.01);
    z-index: 50;
    box-shadow: 20px 20px 60px #00000036;
    transition: transform 0.2s, box-shadow 0.2s ease-in-out;
}

.jobjump-card-button.jobjump-card-button-active:hover {
    background-color: var(--primary-color-10);
}

.jobjump-card-button.jobjump-card-button-negative:hover {
    background-color: var(--negative-color-10);
}

.jobjump-card-button.jobjump-card-button-selected.jobjump-card-button-active {
    background-color: var(--primary-color-50);
}

.jobjump-card-button.jobjump-card-button-selected.jobjump-card-button-negative {
    background-color: var(--negative-color-50);
}