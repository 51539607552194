.jobjump-news-date {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/var(--unnamed-line-spacing-14) Montserrat;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: left;
    font: normal normal normal 10px/14px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
}

.jobjump-news-lead {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-22)/30px var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--primary-color);
    text-align: left;
    font: normal normal 300 22px/30px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}

.jobjump-news-body {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/20px var(--unnamed-font-family-lora);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--jobjump-dark-grey);
    text-align: left;
    font: normal normal normal 14px/20px Lora;
    letter-spacing: 0px; 
}

.truncate-lines-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-lines-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jobjump-news-img img {
  height: 350px;
  width: 100%;
  min-width: 100%;
  object-fit: cover;
}