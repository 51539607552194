.jobjump-decorated-row-diagonal-line {
    background: transparent linear-gradient(90deg, var(--primary-color) 0%, var(--primary-color-30) 100%) 0% 0% no-repeat padding-box;
}

@media screen and (min-width:640px) {
    .jobjump-decorated-row-diagonal-line {
        clip-path: polygon(0 0, 100% 24%, 100% 500%, 0% 500%);
        margin-top:calc(var(--spacing-64) * -1);
        padding-top: calc(var(--spacing-128) + var(--spacing-64) - 20%);
        padding-bottom:var(--spacing-16);
        margin-bottom: 10%;

    }
    .diagonal-wrapper {
        transform: translate(0, 20%);
        margin:auto;
    }
}

@media screen and (max-width:639px) {
    .jobjump-decorated-row-diagonal-line {
        clip-path: polygon(0 0, 100% 5%, 100% 500%, 0% 500%);
        margin-top:calc(var(--spacing-16) * -1);
        padding-top: calc(var(--spacing-32) + var(--spacing-16) - 20%);
        padding-bottom:var(--spacing-8);
        margin-bottom: 40%;
    }
    .diagonal-wrapper {
        transform: translate(0, 5%);
        margin:auto;
    }
}

.jobjump-decorated-row-diagonal-line.no-negative-margin {
    margin-top: 0px !important;
}

