

@media screen and (min-width:640px) {
  .Splash {
    margin-left: var(--spacing-64);
    margin-right: var(--spacing-64);
  }
}

@media screen and (max-width:639px) {
  .Splash {
    margin-left: var(--spacing-16);
    margin-right: var(--spacing-16);
  }
}

.hero-video-text {
  text-shadow: 10px 7px 16px #7f7f7f4c;
  color: var(--jobjump-light-grey);
  text-align: center;
  display: block;
}

.Splash {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background: #000000B3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    height: calc(100vh - var(--menu-height) - 60px);
    overflow: hidden;
}

.video-container {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}

.video-container * img,
.video-container * video {
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index:2;
}

.video-container * img {
  opacity: 0.8;
}


.video-float-mt-5 {
    position: absolute;
    top: calc(50% - 5vh);
    width: 100%;
    z-index: 10;
}