.jobjump-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    max-height: 95vh;
    min-height: 65vh;
    z-index: 10000;
}

.jobjump-modal-overlay {
    z-index:999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: brightness(0.9);
}

.jobjump-modal-sm {
    width: 100%
}

.jobjump-modal-md {
    width: 100%
}

.jobjump-modal-lg {
    width: 100%
}

@media screen and (min-width: 40em) {
    .jobjump-modal-sm {
        width: 33%;
        max-width: calc(var(--row-width) * 0.33);
    }

    .jobjump-modal-md {
        width: 66%;
        max-width: calc(var(--row-width) * 0.66);
    }

    .jobjump-modal-lg {
        width: 100%;
        max-width: var(--row-width);
    }
}

